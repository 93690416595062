<template>
  <div>
    <XgPlayer
        :url="videoUrl"
        :cover="videoCoverUrl"
    />
  </div>
</template>

<script>
import XgPlayer from "@/common/XgPlayer";

export default {
  name: "opens",
  components: {
    XgPlayer
  },
  data() {
    return {
      videoUrl: "",
      videoCoverUrl: "",
    };
  },
  created() {
    this.getQueryParams();
  },
  methods: {
    getQueryParams() {
      const params = new URLSearchParams(window.location.search);
      this.videoUrl = params.get("url") || ""; // If ?url= is not provided, it defaults to an empty string
      this.videoCoverUrl = params.get("cover") || ""; // If ?cover_url= is not provided, it defaults to an empty string
    },
  },
};
</script>

<style scoped>
/* Add any specific styles if needed for this component */
</style>
